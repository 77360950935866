import React from "react";
import Home from "./pages";
import Products from "./pages/Products";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Reviews from "./pages/Reviews";
import Company from "./pages/Company";
import Settings from "./pages/Settings";
import Portfolio from "./pages/Portfolio";

import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";

import Login from "./pages/auth/LoginPage";

import Page404 from "./pages/Page404";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/dashboard" index element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/companies" element={<Company />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route element={<GuestLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/:token"
            element={<ForgotPasswordPage />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
