import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { bufferToDataURL } from "../utils/bufferToDataURL";
import moment from "moment";
import { truncateText } from "../utils/truncateText";
import { getAllProducts } from "../api/functions/products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const { products, setProducts: setProductsContext } = useContext(AppContext);

  const getAllProductsData = async () => {
    try {
      const response = await getAllProducts();
      if (response.status === 200) {
        setProductsContext(response.data.products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    getAllProductsData();
  }, [setProductsContext]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 rounded">
          <nav className="navbar-breadcrumb rounded-2 col-xl-12 col-12 d-flex flex-row p-0 mt-0">
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-start">
              <ul className="navbar-nav mr-lg-2">
                <li className="nav-item ms-0">
                  <h4 className="mb-0">Dashboard</h4>
                </li>
                <li className="nav-item">
                  <div className="d-flex align-items-center">
                    <p className="mb-0">Home</p>
                    <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
                    <p className="mb-0">Main Dashboard</p>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Description</th>
                    <th>Last updated</th>
                  </tr>
                </thead>
                <tbody>
                  {products.length > 0 ? (
                    products.map((product, index) => (
                      <tr key={product._id}>
                        <td>{index + 1}</td>
                        <td className="d-flex align-items-center gap-3">
                          {product?.image && (
                            <img
                              src={bufferToDataURL(product?.image?.data)}
                              alt={`Product ${product._id}`}
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <strong>{product?.title?.en}</strong>
                        </td>
                        <td>{product.price} DH</td>
                        <td>{truncateText(product.description.en, 31)} </td>
                        <td>{moment(product?.updatedAt).fromNow()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="py-3 text-center">
                        No products available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
