import React, { useState } from "react";
import showToast from "./showToast";
import { createCompany } from "../api/functions/company";
import uploadIcon from "../assets/images/icons/upload.svg";

const CompanyForm = ({ fetchCompanies }) => {
  const [imageName, setImageName] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [formData, setFormData] = useState({
    companyName: "",
    companyImage: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setImageName(file.name);
      setFormData({
        ...formData,
        companyImage: file,
      });
    }
  };

  const resetFormData = () => {
    setImageName(null);
    setSelectedImageFile(null);
    setFormData({
      companyName: "",
      companyImage: null,
    });
  };

  const onSubmitCompany = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      formDataToSend.append("companyName", formData.companyName);
      formDataToSend.append("image", formData.companyImage);

      const res = await createCompany(formDataToSend);

      if (res.status === 201) {
        fetchCompanies();
        showToast(res?.data.message.en, "success");
        resetFormData();
      }
    } catch (error) {
      console.error("Error", error);
      showToast(error?.response?.data?.message, "error");
    }
  };

  return (
    <form onSubmit={onSubmitCompany} className="forms-sample">
      {/* Company Form */}
      <div className="form-group">
        <label>
          Company name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          maxLength={40}
          required
        />
      </div>

      <div className="form-group">
        {/* File Upload Section */}
        {selectedImageFile ? (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <img
              src={URL.createObjectURL(selectedImageFile)}
              alt="Selected"
              className="mt-2"
              style={{ maxWidth: "100%", maxHeight: "150px" }}
            />
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploadCompanyImage"
              multiple=""
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploadCompanyImage"
              multiple=""
              onChange={handleFileChange}
            />
            <label className="file-area__label" htmlFor="uploadCompanyImage">
              <span className="uploader-product-media-file-area__label">
                <img src={uploadIcon} alt="" />
                <strong className="t3">
                  {imageName ? imageName : "Upload presentational image"}
                </strong>
              </span>
            </label>
          </div>
        )}
        <div className="UploaderProductUploadSection__description t3 ">
          JPG, PNG formats only. File under 10MB. The main image must have an
          8:5 aspect ratio, the minimum size of 350 x 150 px. Main information
          about your item should be placed in the center of an image, it will
          look better in the ads. Please, check our.
        </div>
      </div>
      <button type="submit" className="btn btn-primary">
        Create new company
      </button>
      <button
        type="button"
        className="btn btn-secondary ml-3"
        onClick={resetFormData}
      >
        Cancel
      </button>
    </form>
  );
};

export default CompanyForm;
