import React from "react";
import { deleteProductById } from "../api/functions/products";
import { toast } from "react-toastify";

const DeleteProduct = ({ product, fetchProducts }) => {
  const handleSaveChanges = async () => {
    try {
      const response = await deleteProductById(product._id);

      if (response.status === 204) {
        fetchProducts();

        toast.info("Deleted successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.warn(`${error.response?.data?.message}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="deleteProduct"
      tabIndex="-1"
      aria-labelledby="deleteProductLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteProductLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to delete the product "{product?.title.en}
              "?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleSaveChanges}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProduct;
