import React, { useContext, useEffect, useState } from "react";
import ReviewForm from "../components/ReviewForm";
import { getAllReviews, getReviewById } from "../api/functions/reviews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteReview from "../components/DeleteReview";
import EditReview from "../components/EditReview";
import { Bar } from "react-chartjs-2";
import { Chart, BarElement } from "chart.js";
import { AppContext } from "../context/AppContext";
import { truncateText } from "../utils/truncateText";
import moment from "moment";
import { BarChart } from "../charts/BarChart";
Chart.register(BarElement);

const Reviews = () => {
  const { reviews, setReviews: setReviewsContext } = useContext(AppContext);

  const [reviewObject, setReviewObject] = useState({});

  const getAllReviewsData = async () => {
    const response = await getAllReviews();
    if (response.status === 200) {
      setReviewsContext(response.data.reviews);
    }
  };

  useEffect(() => {
    getAllReviewsData();
  }, []);

  const getReviewObjectById = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getReviewById(id);
      if (res.status === 200) {
        setReviewObject(res.data.review);
      }
    } catch (err) {
      console.error(err?.response?.data?.message);
    }
  };

  // Calculate the number of reviews given each month
  const monthlyReviewCount = reviews.reduce((acc, review) => {
    const monthKey = moment(review.timestamp).format("MMMM YYYY");
    acc[monthKey] = (acc[monthKey] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(monthlyReviewCount),
    datasets: [
      {
        label: "Reviews per Month",
        data: Object.values(monthlyReviewCount),
        backgroundColor: "rgba(54, 162, 235, 0.5)", // Adjust color as needed
        borderColor: "rgba(54, 162, 235, 1)", // Adjust color as needed
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <DeleteReview review={reviewObject} fetchReviews={getAllReviewsData} />
      <EditReview review={reviewObject} fetchReviews={getAllReviewsData} />

      <div className="row">
        <div className="col-lg-8 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <ReviewForm fetchReviews={getAllReviewsData} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <BarChart chartData={chartData} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped mg-b-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Content</th>
                      <th>Stars</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews?.length > 0 ? (
                      reviews.map((review, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{review.clientName}</td>
                          <td>{truncateText(review.description.en, 100)}</td>
                          <td>
                            {Array.from({ length: review.stars }, (_, i) => (
                              <FontAwesomeIcon
                                key={i}
                                icon={faStar}
                                style={{ color: "yellow" }}
                              />
                            ))}
                          </td>
                          <td className="">
                            <button
                              className="btn btn-success me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editReviewModel"
                              onClick={(e) =>
                                getReviewObjectById(e, review?._id)
                              }
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteReviewModal"
                              onClick={(e) =>
                                getReviewObjectById(e, review?._id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="py-3 text-center">
                          No review available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
