import React, { useEffect, useState } from "react";
import { updateSlider } from "../api/functions/heroSlider";
import showToast from "./showToast";

const SwiperEdit = ({ swiper, fetchSlides }) => {
  const [imageName, setImageName] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    button: "",
    image: null,
  });

  useEffect(() => {
    setFormData({
      title: swiper?.title?.en,
      subtitle: swiper?.subtitle?.en,
      button: swiper?.button?.en,
      image: swiper?.image,
    });
  }, [swiper]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageName(file.name);
      setSelectedImageFile(file);
      setFormData({
        ...formData,
        image: file,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditSlider = async (e, id) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("subtitle", formData.subtitle);
      formDataToSend.append("button", formData.button);
      formDataToSend.append("image", formData?.image);

      const res = await updateSlider(id, formDataToSend);
      if (res.status === 200) {
        fetchSlides();
        showToast(res.data.message.en, "success");
      }
    } catch (err) {
      showToast(err.response.data.message, "error");
    }
  };

  const bufferToDataURL = (buffer) => {
    const base64 = btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return `data:image/jpeg;base64,${base64}`;
  };

  return (
    <div
      className="modal fade"
      id="editSwiper"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editSwiperLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editSwiperLabel">
              Edit Swiper
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleEditSlider(e, swiper._id)}>
            <div className="modal-body">
              <div className="form-group">
                {selectedImageFile ? (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <img
                      src={URL.createObjectURL(selectedImageFile)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                    <input
                      className="file-area__input"
                      id="uploadEditSliderImage"
                      type="file"
                      accept="image/*"
                      multiple=""
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <input
                      className="file-area__input"
                      id="uploadEditSliderImage"
                      type="file"
                      accept="image/*"
                      multiple=""
                      onChange={handleFileChange}
                    />
                    <img
                      src={bufferToDataURL(formData?.image?.data)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  </div>
                )}
                <div className="UploaderProductUploadSection__description t3 ">
                  JPG, PNG formats only. File under 10MB. The main image must
                  have an 8:5 aspect ratio, the minimum size of 336 x 350 px.
                  Main information about your item should be placed in the
                  center of an image, it will look better in the ads. Please,
                  check our.
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={formData?.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="subtitle">Subtitle</label>
                <textarea
                  className="form-control"
                  id="subtitle"
                  name="subtitle"
                  rows="3"
                  value={formData?.subtitle}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="button">Button</label>
                <input
                  type="text"
                  className="form-control"
                  id="button"
                  name="button"
                  value={formData?.button}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-success">
                Update Slider
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SwiperEdit;
