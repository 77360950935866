import React from "react";
import { deleteCategory } from "../api/functions/categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import showToast from "./showToast";

const DeleteCategoryModel = ({ category, fetchAllCategories }) => {
  const handleDeleteAllCategories = async (e, id) => {
    e.preventDefault();
    try {
      const res = await deleteCategory(id);
      if (res.status === 204) {
        fetchAllCategories();
        showToast("category has been deleted successfully!", "info");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
      console.error("Error: ", error?.response?.data?.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="deleteCategory"
      tabIndex="-1"
      aria-labelledby="deleteCategoryLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <span className="text-danger">
              <FontAwesomeIcon icon={faCircleInfo} className="me-1" /> Please be
              careful
            </span>
            ; if you delete this category,
            <span className="font-weight-bold text-danger">
              the products related to this category will be deleted.
            </span>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={(e) => handleDeleteAllCategories(e, category._id)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCategoryModel;
