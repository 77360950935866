import React, { useContext, useEffect, useState } from "react";
import { updateProduct } from "../api/functions/products";
import showToast from "./showToast";
import { AppContext } from "../context/AppContext";
import { bufferToDataURL } from "../utils/bufferToDataURL";

const EditProductModel = ({ product, fetchProducts }) => {
  const [imageName, setImageName] = useState(null);
  const { categories } = useContext(AppContext);
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
    categoryId: "",
    image: null,
  });

  useEffect(() => {
    setFormData({
      title: product?.title.en,
      description: product?.description.en,
      price: product?.price,
      categoryId: product?.categoryId,
      image: product?.image,
    });
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImageName(file.name);
      setFormData({
        ...formData,
        image: file,
      });
    }
  };

  const resetFormData = () => {
    setImageName(null);
    setSelectedFile(null);
    setFormData({
      title: "",
      description: "",
      price: 0,
      categoryId: "",
      image: null,
    });
  };

  const handleEditProduct = async (e, id) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData?.title);
    formDataToSend.append("description", formData?.description);
    formDataToSend.append("price", formData?.price);
    formDataToSend.append("categoryId", formData?.categoryId);
    formDataToSend.append("image", formData?.image);

    try {
      const response = await updateProduct(id, formDataToSend);

      if (response.status === 200) {
        fetchProducts();
        resetFormData();
        showToast(response.data.message.en, "success");
      }
    } catch (error) {
      console.error(error.response);
      showToast(error.response.data.message, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="editProduct"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editProductLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editProductLabel">
              Edit product {product?.title.en}
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            onSubmit={(e) => handleEditProduct(e, product?._id)}
            className="forms-sample"
          >
            <div className="modal-body">
              {/* Product Form */}
              <div className="form-group">
                <label>
                  Product Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  maxLength={30}
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows="4"
                ></textarea>
              </div>
              <div className="form-group">
                <label>
                  Category <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name.en}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Price <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  min={5}
                  max={10000}
                  required
                />
              </div>
              <div className="form-group">
                {selectedFile ? (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                    <input
                      className="file-area__input"
                      id="uploader-product-presentational-media-input-2"
                      type="file"
                      accept="image"
                      multiple=""
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <input
                      className="file-area__input"
                      id="uploader-product-presentational-media-input-2"
                      type="file"
                      accept="image"
                      multiple=""
                      onChange={handleFileChange}
                    />
                    <img
                      src={bufferToDataURL(product?.image?.data)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  </div>
                )}
                <div className="UploaderProductUploadSection__description t3 ">
                  JPG, PNG formats only. File under 10MB. The main image must
                  have an 8:5 aspect ratio, the minimum size of 336 x 350 px.
                  Main information about your item should be placed in the
                  center of an image, it will look better in the ads. Please,
                  check our.
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Update product
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProductModel;
