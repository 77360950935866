import React, { useEffect, useState } from "react";
import showToast from "./showToast";
import { updateReview } from "../api/functions/reviews";
import StarRating from "./StarRating";

const EditReview = ({ review, fetchReviews }) => {
  const [formData, setFormData] = useState({
    clientName: "",
    description: "",
    stars: 3,
  });

  useEffect(() => {
    setFormData({
      clientName: review?.clientName,
      description: review?.description?.en,
      stars: review?.stars,
    });
  }, [review]);

  const handleEditReview = async (e, id) => {
    e.preventDefault();

    try {
      const response = await updateReview(id, formData);
      if (response.status === 200) {
        fetchReviews();

        showToast(response.data.message.en, "info");
        setFormData({
          clientName: "",
          description: "",
          stars: 3,
        });
      }
    } catch (error) {
      console.error("err:", error.response);
    }
  };

  return (
    <div
      className="modal fade"
      id="editReviewModel"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editReviewModelTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editReviewModelTitle">
              Edit review for client {review?.clientName}
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            onSubmit={(e) => handleEditReview(e, review._id)}
            className="forms-sample"
          >
            <div className="modal-body">
              <div className="form-group">
                <StarRating
                  value={formData.stars}
                  onChange={(stars) => setFormData({ ...formData, stars })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="clientName">Client name</label>
                <input
                  type="text"
                  id="clientName"
                  name="clientName"
                  className="form-control"
                  placeholder="eg. John Doe"
                  value={formData.clientName}
                  onChange={(e) =>
                    setFormData({ ...formData, clientName: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  rows="4"
                  className="form-control"
                  placeholder="eg. I would like to thank..."
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-success">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditReview;
