import { toast } from "react-toastify";

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
};

const showToast = (message, type) => {
  return toast[type](message, toastConfig);
};

export default showToast;
