import React, { useContext, useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { AppContext } from "../context/AppContext";
import { bufferToDataURL } from "../utils/bufferToDataURL";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import NewImage from "../components/portfolio/NewImage";
import Gallery from "react-photo-album";

const Portfolio = () => {
  const { portfolioImages, setPortfolioImages: setPortfolioImagesContext } =
    useContext(AppContext);

  const fetchImages = async () => {
    const res = await getAllPortfolioImages();
    if (res.status === 200) {
      setPortfolioImagesContext(res.data.images);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const convertImagesForGallery = () => {
    return portfolioImages.slice(0, 4).flatMap((image) =>
      image.images.map((item) => ({
        src: bufferToDataURL(item?.buffer.data),
        width: 4,
        height: 3,
      }))
    );
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Upload New Portfolio Image</h4>
            <p className="card-title">The images will display as a gallery</p>
            <NewImage fetchImages={fetchImages} />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {portfolioImages.length > 0 ? (
              <Gallery layout="rows" photos={convertImagesForGallery()} />
            ) : (
              <p>There's no images to show</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
