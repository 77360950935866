import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createProduct } from "../api/functions/products";
import { getAllCategories } from "../api/functions/categories";
import showToast from "./showToast";
import { AppContext } from "../context/AppContext";
import uploadIcon from "../assets/images/icons/upload.svg";
import { BeatLoader } from "react-spinners";

const ProductForm = ({ fetchProducts }) => {
  const { categories, setCategories: setCategoriesContext } =
    useContext(AppContext);

  const [imageName, setImageName] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [categoriesList, setCategoriesList] = useState(categories);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
    categoryId: "",
    productImage: null,
  });

  const getAllCategoriesData = async () => {
    const response = await getAllCategories();
    if (response.status === 200) {
      setCategoriesContext(response.data.categories);
    }
  };

  useEffect(() => {
    getAllCategoriesData();
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setImageName(file.name);
      setFormData({
        ...formData,
        productImage: file,
      });
    }
  };

  const onSubmitProduct = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("price", formData.price);
      formDataToSend.append("categoryId", formData.categoryId);
      formDataToSend.append("image", formData.productImage);

      const response = await createProduct(formDataToSend);

      if (response.status === 201) {
        fetchProducts();
        showToast(response.data.message.en, "success");
        resetFormData();
      }
    } catch (error) {
      console.error(error.response);
      showToast(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const resetFormData = () => {
    setImageName(null);
    setSelectedImageFile(null);
    setFormData({
      title: "",
      description: "",
      price: 0,
      categoryId: "",
      productImage: null,
    });
  };

  return (
    <form onSubmit={onSubmitProduct} className="forms-sample">
      {/* Product Form */}
      <div className="form-group">
        <label>
          Product Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          maxLength={20}
          required
        />
      </div>
      <div className="form-group">
        <label>
          Description <span className="text-danger">*</span>
        </label>
        <textarea
          className="form-control"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows="4"
        ></textarea>
      </div>
      <div className="form-group">
        <label>
          Category <span className="text-danger">*</span>
        </label>
        <select
          className="form-control"
          name="categoryId"
          value={formData.categoryId}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>
            Select Category
          </option>
          {categories?.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name.en}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>
          Price <span className="text-danger">*</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          min={0}
          max={10000}
          required
        />
      </div>
      <div className="form-group">
        {selectedImageFile ? (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <img
              src={URL.createObjectURL(selectedImageFile)}
              alt="Selected"
              className="mt-2"
              style={{ maxWidth: "100%", maxHeight: "150px" }}
            />
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploader-product-presentational-media-input-1"
              multiple=""
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploader-product-presentational-media-input-1"
              multiple=""
              onChange={handleFileChange}
            />
            <label
              className="file-area__label"
              htmlFor="uploader-product-presentational-media-input-1"
            >
              <span className="uploader-product-media-file-area__label">
                <img src={uploadIcon} alt="" />
                <strong className="t3">
                  {imageName ? imageName : "Upload presentational image"}
                </strong>
              </span>
            </label>
          </div>
        )}
        <div className="UploaderProductUploadSection__description t3 ">
          JPG, PNG formats only. File under 10MB. The main image must have an
          8:5 aspect ratio, the minimum size of 336 x 350 px. Main information
          about your item should be placed in the center of an image, it will
          look better in the ads. Please, check our.
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? (
          <BeatLoader color={"#fff"} loading={loading} size={8} />
        ) : (
          "Create new product"
        )}
      </button>
      <button
        type="button"
        className="btn btn-secondary ml-3"
        onClick={resetFormData}
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductForm;
