import React, { useContext, useEffect, useState } from "react";

import NewSlide from "../components/NewSlide";
import SwiperTable from "../components/SwiperTable";
import Contacts from "../components/Contacts";
import { getAllSlideItems } from "../api/functions/heroSlider";
import { AppContext } from "../context/AppContext";

const Settings = () => {
  const { setSliders: setSliderListContext } = useContext(AppContext);

  const getAllSlidesData = async () => {
    const res = await getAllSlideItems();
    if (res.status === 200) {
      setSliderListContext(res.data.sliders);
    }
  };

  useEffect(() => {
    getAllSlidesData();
  }, []);

  return (
    <div className="settings az-content az-content-dashboard">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add new slide</h4>
                <p className="card-text">
                  Add a new slide to your homepage by filling out this form. You
                  can add images
                </p>
                <NewSlide fetchSlidesData={getAllSlidesData} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <SwiperTable fetchSlidesData={getAllSlidesData} />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Social Media Contacts</h4>
                <p>You can add your social media links here.</p>
                <Contacts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
