import React, { useState } from "react";
import { forgotPassword } from "../../api/functions/auth";

const ForgotPassword = ({ token }) => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await forgotPassword({
        email,
        newPassword,
        confirmNewPassword,
      });

      if (res.status === 200) {
        setIsSuccess(res.data.message.en);
      }
    } catch (err) {
      setIsError(err?.response?.data?.message);
      setTimeout(() => {
        setIsError("");
      }, 3000);
    }
  };

  return (
    <div className="row justify-content-center">
      <h2 className="card-title text-center mb-4 text-success">
        Reset Password
      </h2>
      {!isSuccess ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={token ? true : false}
            />
          </div>

          {token ? (
            <>
              <div className="form-group">
                <input
                  type="password"
                  id="newPassword"
                  className="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  id="confirmNewPassword"
                  className="form-control"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </div>
            </>
          ) : null}

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">
              Reset Password
            </button>
          </div>
          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </form>
      ) : (
        <div className="alert alert-success" role="alert">
          {isSuccess}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
