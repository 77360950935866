import React, { useContext, useEffect } from "react";
import { getAllProducts } from "../api/functions/products";
import ProductForm from "../components/ProductForm";
import CategoryForm from "../components/CategoryForm";
import ProductsTable from "../components/ProductsTable";
import DeleteAllProductsModel from "../components/DeleteAllProductsModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../context/AppContext";

const Products = () => {
  const { products, setProducts: setProductsContext } = useContext(AppContext);

  const getAllProductsData = async () => {
    const response = await getAllProducts();
    if (response.status === 200) {
      setProductsContext(response.data.products);
    }
  };

  useEffect(() => {
    getAllProductsData();
  }, []);

  return (
    <>
      <DeleteAllProductsModel fetchProducts={getAllProductsData} />
      <div className="row">
        {/* Products */}
        <div className="col-lg-8 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Product</h4>
              <ProductForm fetchProducts={getAllProductsData} />
            </div>
          </div>
        </div>
        {/* Categories */}
        <div className="col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add New Category</h4>
              <CategoryForm />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="card-title">Product Table</h4>
                  <p className="card-description">
                    products table with list of products data
                  </p>
                </div>
                <div>
                  {products.length > 0 && (
                    <button
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteAllProductsModal"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      delete All products
                    </button>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <ProductsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
