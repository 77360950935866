import React from "react";
import { deleteAllProducts } from "../api/functions/products";
import showToast from "./showToast";

const DeleteAllProductsModel = ({ fetchProducts }) => {
  const handleDeleteAllProducts = async (e) => {
    e.preventDefault();
    try {
      const res = await deleteAllProducts();
      if (res.status === 204) {
        fetchProducts();
        showToast("All products has been deleted successfully!", "info");
      }
    } catch (error) {
      console.error("error => ", error);
      showToast(error.response?.data?.message, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="deleteAllProductsModal"
      tabIndex="-1"
      aria-labelledby="deleteAllProductsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteAllProductsModalLabel">
              Are you sure you want to delete all products
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            This action cannot be undone! All of your products will be deleted.
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteAllProducts}
            >
              Delete All Products
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAllProductsModel;
