import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faGear,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import face5 from "../assets/images/faces/face5.jpg";
import brandLogo from "../assets/images/logo.svg";
import MiniLogo from "../assets/images/logo-mini.svg";
import { AppContext } from "../context/AppContext";

const Navbar = () => {
  const { removeToken } = useContext(AppContext);

  const ShowSidebarIcons = () => {
    document.querySelector("body").classList.toggle("sidebar-icon-only");
  };

  const showSidebar = () => {
    document.getElementById("sidebar").classList.toggle("active");
  };

  const handleRemoveToken = () => {
    removeToken();
    window.location.reload();
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
          <a className="navbar-brand brand-logo" href="index.html">
            <img src={brandLogo} alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="index.html">
            <img src={MiniLogo} alt="logo" />
          </a>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={ShowSidebarIcons}
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav mr-lg-2">
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link"
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <img src={face5} alt="profile" />
              <span className="nav-profile-name">Achraf Lafkiri</span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item" href="#">
                <FontAwesomeIcon
                  icon={faGear}
                  className="text-primary me-2 text-dark"
                />
                Settings
              </a>
              <a className="dropdown-item" href="#" onClick={handleRemoveToken}>
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="text-primary me-2 text-dark"
                />
                Logout
              </a>
            </div>
          </li>
          <li className="nav-item nav-user-status dropdown">
            <p className="mb-0">Last login was 23 hours ago.</p>
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center"
              id="messageDropdown"
              href="#"
              data-toggle="dropdown"
            >
              <FontAwesomeIcon icon={faGear} className="mx-0" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="messageDropdown"
            >
              <p className="mb-0 font-weight-normal float-left dropdown-header">
                Settings will be available soon.
              </p>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={showSidebar}
        >
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
