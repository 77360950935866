import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { getAllContacts, updateContacts } from "../api/functions/contacts";
import showToast from "../components/showToast";
import { useEffect, useState } from "react";

const Contacts = () => {
  const [contacts, setContacts] = useState([
    { id: 1, icon: faFacebook, name: "Facebook", link: "" },
    { id: 2, icon: faInstagram, name: "Instagram", link: "" },
    { id: 3, icon: faTwitter, name: "Twitter", link: "" },
    { id: 4, icon: faLinkedin, name: "LinkedIn", link: "" },
    { id: 5, icon: faYoutube, name: "YouTube", link: "" },
    { id: 6, icon: faWhatsapp, name: "Whatsapp", link: "" },
  ]);

  const handleLinkChange = (id, value) => {
    const updatedContacts = contacts.map((contact) =>
      contact.id === id ? { ...contact, link: value } : contact
    );

    setContacts(updatedContacts);
  };

  const getAllContactsData = async () => {
    const res = await getAllContacts();
    if (res.status === 200) {
      setContacts([
        {
          id: 1,
          icon: faFacebook,
          name: "Facebook",
          link: res.data.contacts[0].Facebook,
        },
        {
          id: 2,
          icon: faInstagram,
          name: "Instagram",
          link: res.data.contacts[0].Instagram,
        },
        {
          id: 3,
          icon: faTwitter,
          name: "Twitter",
          link: res.data.contacts[0].Twitter,
        },
        {
          id: 4,
          icon: faLinkedin,
          name: "LinkedIn",
          link: res.data.contacts[0].LinkedIn,
        },
        {
          id: 5,
          icon: faYoutube,
          name: "YouTube",
          link: res.data.contacts[0].YouTube,
        },
        {
          id: 6,
          icon: faWhatsapp,
          name: "Whatsapp",
          link: res.data.contacts[0].Whatsapp,
        },
      ]);
    }
  };

  useEffect(() => {
    getAllContactsData();
  }, []);

  const isValidWhatsAppLink = (link) => {
    const whatsappPattern = /^212\d{9}$/;
    return whatsappPattern.test(link);
  };

  const handleSaveContacts = async () => {
    const whatsappLink = contacts.find(
      (contact) => contact.name === "Whatsapp"
    ).link;

    if (whatsappLink) {
      if (!isValidWhatsAppLink(whatsappLink)) {
        showToast(
          "Invalid WhatsApp link format. Please enter a valid link.",
          "error"
        );
        return;
      }
    }

    const contactsToSend = {
      Facebook: contacts.find((contact) => contact.name === "Facebook").link,
      Instagram: contacts.find((contact) => contact.name === "Instagram").link,
      Twitter: contacts.find((contact) => contact.name === "Twitter").link,
      LinkedIn: contacts.find((contact) => contact.name === "LinkedIn").link,
      YouTube: contacts.find((contact) => contact.name === "YouTube").link,
      Whatsapp: contacts.find((contact) => contact.name === "Whatsapp").link,
    };

    try {
      const res = await updateContacts(contactsToSend);
      if (res.status === 201) {
        showToast(res.data.message.en, "success");
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  return (
    <>
      <div className="row">
        {contacts.map((contact) => (
          <div className="col-md-6" key={contact.id}>
            <div className="form-group position-relative" key={contact.id}>
              <input
                type="text"
                value={contact.link}
                onChange={(e) => handleLinkChange(contact.id, e.target.value)}
                className="form-control"
                placeholder={
                  contact.name === "Whatsapp"
                    ? "Enter your number (e.g., 21212345678)"
                    : `Enter ${contact.name} link`
                }
              />
              <FontAwesomeIcon
                icon={contact.icon}
                className="position-absolute top-50 end-20 translate-middle-y"
                fontSize={19}
                color="#000"
              />
            </div>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={handleSaveContacts}
      >
        Save Contacts
      </button>
    </>
  );
};

export default Contacts;
